// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import axios from 'axios'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React, { useState } from 'react'
import Button from '../basic/Button'
import Checkbox from '../basic/Checkbox'
import { Form, FormGroup, Input, Label } from '../basic/Forms'
import { Col, Container, Row } from '../basic/Grid'
import Modal from '../basic/Modal'
import messages from './ProductTeaser.lang'
import baseStyles from './ProductTeaser.scss'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'

const QSImg = (
  <StaticImage src="../../images/products/quickstart_mockups.png" alt="" objectFit="cover" className="image" />
)

const QSLogo = (
  <StaticImage
    src="../../images/products/quickstart/quickstart_logo.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)

type Props = {
  ...StyleProps
}

const FORM_URL = 'https://getform.io/f/4c6d7595-153f-4d43-8c1a-e6ad4df3fc5e'

const ProductTeaser = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()
  const [pageTitle, setPageTitle] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [finished, setFinished] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const setHiddenTitleField = () => {
    if (global.window && global.window.document) {
      setPageTitle(`${document.title} | ${locale.toUpperCase()}`)
    }
  }
  const setTextCustomMessage = e => {
    e.target.setCustomValidity(formatMessage(messages.requiredTextInput))
  }
  const setCheckboxCustomMessage = e => {
    e.target.setCustomValidity(formatMessage(messages.requiredCheckbox))
  }
  const clearValidityMessage = e => {
    e.target.setCustomValidity('')
  }
  const validateForm = e => {
    if (global.window && global.window.document) {
      if (e.target.type && e.target.type === 'checkbox') {
        const requiredCheckBox = e.target
        if (requiredCheckBox) {
          requiredCheckBox.oninvalid = e => {
            setCheckboxCustomMessage(e)
          }
          requiredCheckBox.onchange = e => {
            clearValidityMessage(e)
          }
        }
      }
    }
    setSubmitted(true)
  }
  const submitForm = e => {
    e.preventDefault()
    setSubmitting(true)
    const formData = new FormData(e.target)
    axios({
      method: 'post',
      url: FORM_URL,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(() => {
        setFinished(true)
      })
      .catch(() => {
        setFinished(true)
      })
  }
  return (
    <div className={classNames(styles.root)}>
      <Container className={styles.container} fluid>
        <Row className={styles.row}>
          <Col xs="12" md="6" className={classNames(styles.col, styles.colImg)}>
            <div className={styles.imageWrap}>
              <div className={styles.image}>{QSImg}</div>
            </div>
          </Col>
          <Col xs="12" md="6" className={classNames(styles.col, styles.colContent)}>
            <div className={styles.content}>
              <span className={styles.imageLogo}>{QSLogo}</span>
              <div className={styles.text}>
                <FormattedMessageSanitized {...messages.teaserContent} />
              </div>
              <div className={styles.actionWrapper}>
                <div className={styles.btnLink}>
                  <Button
                    styles={styles.btn}
                    tag="span"
                    color="accent"
                    iconRight="icon-arrow_03-right-medium"
                    onClick={() => setModalVisible(true)}
                  >
                    {formatMessage(messages.teaserBtn)}
                  </Button>
                  <Link to="/products/quickstart/" className={styles.textLink}>
                    {formatMessage(messages.quickStartpageLink)}
                  </Link>
                </div>
                <div>
                  <Modal
                    visible={modalVisible}
                    styles={styles.modal}
                    shouldCloseOnOverlayClick
                    onClose={() => setModalVisible(false)}
                  >
                    <div className={styles.modalContent}>
                      {!finished ? (
                        <>
                          <h3 className={styles.modalTitle}>
                            <FormattedMessageSanitized {...messages.teasermodaltitle} />
                          </h3>
                          <div className={classNames(styles.formRoot, submitted && styles.isValidated)}>
                            <Form
                              className={styles.form}
                              action={FORM_URL}
                              method="POST"
                              onInvalid={validateForm}
                              onSubmit={submitForm}
                            >
                              <FormGroup className={styles.group}>
                                <Label className={styles.label}>{formatMessage(messages.labelName)}</Label>
                                <Input
                                  type="text"
                                  placeholder={formatMessage(messages.placeholderName)}
                                  name="name"
                                  required
                                  styles={styles.input}
                                  onInvalid={setTextCustomMessage}
                                  onInput={clearValidityMessage}
                                />
                              </FormGroup>
                              <FormGroup className={styles.group}>
                                <Label className={styles.label}>{formatMessage(messages.labelCompany)}</Label>
                                <Input
                                  type="text"
                                  placeholder={formatMessage(messages.placeholderCompany)}
                                  name="company"
                                  required
                                  styles={styles.input}
                                  onInvalid={setTextCustomMessage}
                                  onInput={clearValidityMessage}
                                />
                              </FormGroup>
                              <FormGroup className={styles.group}>
                                <Label className={styles.label}>{formatMessage(messages.labelMail)}</Label>
                                <Input
                                  type="email"
                                  placeholder={formatMessage(messages.placeholderMail)}
                                  name="email"
                                  required
                                  styles={styles.input}
                                  onInvalid={setTextCustomMessage}
                                  onInput={clearValidityMessage}
                                />
                              </FormGroup>
                              <FormGroup className={styles.group}>
                                <Label className={styles.label}>{formatMessage(messages.labelFreetext)}</Label>
                                <Input
                                  type="text"
                                  placeholder={formatMessage(messages.placeholderFreetext)}
                                  name="message"
                                  styles={styles.input}
                                  onInvalid={setTextCustomMessage}
                                  onInput={clearValidityMessage}
                                />
                              </FormGroup>
                              <Checkbox required className={styles.checkbox} onChange={setHiddenTitleField}>
                                <div
                                  className={styles.checkboxText}
                                  dangerouslySetInnerHTML={{ __html: formatMessage(messages.checkboxText)
                                      .replaceAll("'", '') }}
                                />
                              </Checkbox>
                              <Input type="hidden" name="page" value={pageTitle} />
                              <Button
                                type="submit"
                                styles={styles.modalBtn}
                                color="accent"
                                iconRight="icon-arrow_03-right-medium"
                                disabled={submitting}
                              >
                                {formatMessage(messages.labelButton)}
                              </Button>
                            </Form>
                          </div>
                        </>
                      ) : (
                        <>
                          <span className={classNames(styles.icon, 'icon-clock')} />
                          <h3 className={styles.modalTitleSuccess}>
                            <FormattedMessageSanitized {...messages.successModalTitle} />
                          </h3>
                          <div className={styles.modalsubtitle}>{formatMessage(messages.successModalText)}</div>
                          <div className={styles.btnSuccessWrap}>
                            <Button
                              tag="span"
                              color="accent"
                              iconRight="icon-arrow_03-right-medium"
                              onClick={() => setModalVisible(false)}
                            >
                              {formatMessage(messages.successModalBtn)}
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </Modal>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(ProductTeaser)
