import messages from './Solutions.lang'

export const solutionsList = {
  ITSystemModernization: {
    title: messages.ITSystemModernizationTitle,
    localLink: '/solutions/it-system-modernization/',
    icon: 'icon-products',
    color: 'accent',
    copy: messages.ITSystemModernizationCopy
  },
  DigitalizationAndITConsulting: {
    title: messages.DigitalizationAndITConsultingTitle,
    localLink: '/contact',
    icon: 'icon-consulting2',
    color: 'primary',
    copy: messages.DigitalizationAndITConsultingCopy
  },
  PlatformAndEnterpriseSolutions: {
    title: messages.PlatformAndEnterpriseSolutionsTitle,
    localLink: '/contact',
    icon: 'icon-platform',
    color: 'secondary',
    copy: messages.PlatformAndEnterpriseSolutionsCopy
  },
  BusinessAndProcessSoftware: {
    title: messages.BusinessAndProcessSoftwareTitle,
    localLink: '/contact',
    icon: 'icon-process',
    color: 'tertiary',
    copy: messages.BusinessAndProcessSoftwareCopy
  }
}
