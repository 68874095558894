/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  teaserContent: 'The Quickstart package is designed to enable customers to continue using the underlying TravelSandbox® in the future and to further develop their platform on this basis in a flexible, high- quality and stable manner.',
  teaserBtn: 'Request a demo',
  quickStartpageLink: 'Learn more',
  labelName: 'Your name',
  labelFreetext: 'Your message',
  placeholderName: 'Name *',
  labelCompany: 'Your company',
  placeholderCompany: 'Company *',
  labelMail: 'Your e-mail address',
  placeholderMail: 'E-Mail *',
  placeholderFreetext: 'What are you curious about ...',
  checkboxText: "I accept the '<a href=\"/en/privacy-policy/\" target=\"_blank\">privacy policy</a>' *",
  labelButton: 'Request a demo',
  requiredTextInput: 'Please fill in this field',
  requiredCheckbox: 'Please tick this box if you want to proceed',
  successModalText: 'Thanks for your request',
  successModalTitle: 'We will get back to you as soon as possible',
  successModalBtn: 'Learn more about Quickstart',
  teasermodaltitle: 'Request a <b>live demonstration</b> for <b>Quickstart</b>'
})
