// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import { Container } from '../../components/basic/Grid'
import { solutionsList } from './Solutions'
import messages from './Solutions.lang'
import baseStyles from './Solutions.scss'

type Props = {
  ...StyleProps,
  solutions?: Array<string>,
  hideTitle?: boolean
}

const Solutions = ({
  styles,
  solutions = [
    'ITSystemModernization',
    'DigitalizationAndITConsulting',
    'PlatformAndEnterpriseSolutions',
    'BusinessAndProcessSoftware'
  ],
  hideTitle
}: Props) => {
  const { formatMessage } = useIntl()

  return (
    <Container className={styles.root} fluid>
      {!hideTitle && (
        <h2 id="solutions" className={styles.title}>
          {formatMessage(messages.title)}
        </h2>
      )}
      <section className={styles.teaserSection}>
        {solutions.map((item, i) => (
          <Link
            key={'solution' + i}
            to={solutionsList[item].localLink}
            className={styles.link}
            activeClassName={styles['is-active']}
            partiallyActive
          >
            <span
              className={classNames(
                styles.teaserIcon,
                solutionsList[item].icon,
                styles['is-' + solutionsList[item].color]
              )}
            />
            <h3 className={styles.teaserTitle}>{formatMessage(solutionsList[item].title)}</h3>
            <p className={styles.teaserCopy}>{formatMessage(solutionsList[item].copy)}</p>
          </Link>
        ))}
      </section>
    </Container>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Solutions)
