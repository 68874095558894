/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'Our solution fields',
  ITSystemModernizationTitle: 'IT System Modernization',
  ITSystemModernizationCopy: 'Take the chance to rethink the digitalization & modernization of all processes and let your vision become reality.',
  DigitalizationAndITConsultingTitle: 'Digital transformation & IT Consulting',
  DigitalizationAndITConsultingCopy: 'The right decisions for future security, flexibility and growth. We help with the digital transformation and digitalization.',
  PlatformAndEnterpriseSolutionsTitle: 'Platform & Enterprise Solutions',
  PlatformAndEnterpriseSolutionsCopy: 'No fear of grown systems, the big relaunch or a necessary further development. Flexible solutions, thought out for the long term. ',
  BusinessAndProcessSoftwareTitle: 'Business & Process Software',
  BusinessAndProcessSoftwareCopy: 'Replace old tools, optimize your digital processes and discover new potential in efficiency, sales and organization.'
})
